<template>
    <Modal :modal-title="title"
           :dialog="employeeFormVisibility"
           max-width="1000px"
           :persistent="true"
           dense
           @keydown.enter="showConfirmation"
           @keydown.esc="closeWithConfirmation"
           @close="close"
           @visibility="visibility($event)">
        <template v-slot:content>
            <ManageEmployeeForm ref="empForm"
                                @fetch="emitFetch"
                                :position-type="positionType"
                                :is-editable="isEditable"></ManageEmployeeForm>
            <ConfirmationDialog :dialog="showConfirmationDialog"
                                data-test="ConfirmationDialog"
                                @confirm="handleConfirmation($event)"
                                :text="$t('messages.save_changes')"
                                :action-button-title="$t('buttons.save')"></ConfirmationDialog>
            <span data-test="testKeydownSpan"></span>

            <ValidationError v-if="showError"></ValidationError>
        </template>
        <template v-slot:footer>
            <v-btn color="secondary"
                   outlined
                   @click="close">{{ $t('buttons.cancel') }}
            </v-btn>
            <v-btn color="secondary"
                   :loading="loader"
                   :disabled="loader"
                   @click="save">{{ $t('buttons.save') }}
            </v-btn>
        </template>
    </Modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import ConfirmationDialog from '@/components/widgets/dialogs/ConfirmationDialog';
import ManageEmployeeForm from '@/components/employees/manageEmployee/ManageEmployeeForm';
import ValidationError from '@/components/widgets/ValidationError';

export default {
    name: 'ManageEmployee',
    components: { Modal, ConfirmationDialog, ManageEmployeeForm, ValidationError },
    props: {
        positionType: {
            type: String,
            required: false
        }
    },
    data: () => ({
        showConfirmationDialog: false,
        loader: false,
        showError: false
    }),
    computed: {
        ...mapGetters('manageEmployee', [
            'isLoginDataChanged'
        ]),
        ...mapState('osm', [
            'device'
        ]),
        ...mapState('manageEmployee', [
            'employeeFormVisibility',
            'editEmployeeId',
            'employeeDetailsVisibility'
        ]),
        isFieldsDirty () {
            return this.$refs.empForm.isFormDirty;
        },
        isEditable () {
            return this.editEmployeeId !== null;
        },
        title () {
            if (this.isEditable) {
                switch (this.positionType) {
                case 'elements':
                    return this.$t('elements.edit_title');
                default:
                    return this.$t('employees.edit_title');
                }
            } else {
                switch (this.positionType) {
                case 'elements':
                    return this.$t('elements.add_title');
                default:
                    return this.$t('employees.add_title');
                }
            }
        }
    },
    methods: {
        close () {
            this.$store.commit('customers/SET_FOCUSED_FIELD', null);
            this.$store.commit('manageEmployee/SET_EMPLOYEE_FORM_VISIBILITY', false);
            this.$store.commit('manageEmployee/RESET_EMPLOYEE_DATA');
            this.$store.commit('manageEmployee/RESET_LOGIN_DATA');
            if (!this.employeeDetailsVisibility) {
                this.$store.dispatch('manageEmployee/clearActiveEmployee').then(() => {
                });
            }
            this.$refs.empForm.resetValidation();
            this.$refs.empForm.closeChangeLogin();
        },
        visibility (event) {
            this.$store.commit('manageEmployee/SET_EMPLOYEE_FORM_VISIBILITY', event);
        },
        closeWithConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            } else {
                this.close();
            }
        },
        showConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            }
        },
        emitFetch () {
            this.$emit('fetch');
        },
        createEmployee () {
            this.loader = true;
            this.$refs.empForm.validate()
                .then(valid => {
                    let formValid;
                    if (typeof (valid) === 'boolean') {
                        formValid = valid;
                    } else {
                        formValid = valid.every(e => e === true);
                    }
                    if (formValid) {
                        return this.$store.dispatch('manageEmployee/createEmployee')
                            .then((data) => {
                                const employeeId = data.data.data.id;
                                if (this.device?.deviceSerialNumber && this.device?.deviceType) {
                                    this.$store.dispatch('osm/createDevice', employeeId);
                                }
                                this.$store.commit('manageEmployee/SET_EDIT_EMPLOYEE_ID', employeeId);
                                this.$store.dispatch('manageEmployee/changeLoginData')
                                    .then(() => {
                                        this.$emit('fetch');
                                        this.close();
                                    });
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        updateEmployee () {
            this.loader = true;
            this.$refs.empForm.validate()
                .then(valid => {
                    let formValid;
                    if (typeof (valid) === 'boolean') {
                        formValid = valid;
                    } else {
                        formValid = valid.every(e => e === true);
                    }
                    if (formValid) {
                        return this.$store.dispatch('manageEmployee/updateEmployee', this.editEmployeeId)
                            .then(() => {
                                if (this.device?.deviceSerialNumber && this.device?.deviceType) {
                                    if (this.device?.id) {
                                        this.$store.dispatch('osm/updateDevice', this.device.id);
                                    } else {
                                        this.$store.dispatch('osm/createDevice', this.editEmployeeId);
                                    }
                                } else {
                                    this.device?.id && this.$store.dispatch('osm/deleteDevice', this.device.id);
                                }

                                if (this.isLoginDataChanged) {
                                    this.$store.dispatch('manageEmployee/changeLoginData')
                                        .then(() => {
                                            this.$emit('fetch');
                                            this.close();
                                        });
                                } else {
                                    this.$emit('fetch');
                                    this.close();
                                }
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        save () {
            if (this.isEditable) {
                this.updateEmployee();
            } else {
                this.createEmployee();
            }
        },
        handleConfirmation (event) {
            if (event) {
                this.save();
                this.showConfirmationDialog = false;
            } else {
                this.showConfirmationDialog = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
